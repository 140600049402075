<template>
  <MDBRow class="p-0 bg-dark bg-gradient position-relative">
    <div
      class="d-flex flex-column justify-content-center align-items-center text-center"
      id="hero"
      :style="backgroundStyles"
    >
      <NuxtImg
        src="/images/profile_square.jpg"
        class="rounded-circle icon visual-only"
        format="webp"
        width="140"
        height="140"
        alt=""
        role="presentation"
        placeholder
      />
      <h1 class="title" v-text="title"></h1>
      <h2 class="tagline" v-text="tagline"></h2>
      <Icon
        id="scroll-plz"
        name="tabler:arrow-move-down"
        size="60"
        color="white"
        class="d-block d-sm-none position-absolute bottom-0 start-50 translate-middle-x mb-2 opacity-0"
      />
    </div>
  </MDBRow>
</template>

<script lang="ts" setup>
import { MDBRow } from "mdb-vue-ui-kit";

const { title, tagline } = useAppConfig();

const img = useImage();
const backgroundStyles = computed(() => {
  const imgUrl = img("/images/header.webp", { width: 1920 });
  return { backgroundImage: `url('${imgUrl}')` };
});

const { stop } = useTimeoutFn(() => {
  const scrollPlz = document.getElementById("scroll-plz");
  scrollPlz?.classList.replace("opacity-0", "opacity-100");
}, 3000);
const { y } = useWindowScroll();
watchOnce(y, () => stop());
</script>

<style lang="scss">
@use "../assets/styles/variables" as v;

#hero {
  background-position: center;
  background-size: cover;
  padding: 100px 0;

  .title {
    font-family: "Montserrat", sans-serif;
    color: v.$primary;
    font-size: 40px;
    font-size: clamp(40px, 10vw, 100px);
    font-weight: 600;
  }

  .tagline {
    font-family: "Montserrat", sans-serif;
    color: v.$white;
    font-size: 20px;
    font-size: clamp(20px, 3vw, 35px);
    letter-spacing: 5px;
  }

  .icon {
    border: 9px solid rgba(v.$black, 0.05);
  }

  #scroll-plz {
    transition: opacity 2s linear;
    animation: 5s scroll 7s infinite;
  }

  @keyframes scroll {
    0% {
      translate: 0;
    }
    20% {
      translate: 0 10px;
    }
    40% {
      translate: 0;
    }
  }
}
</style>
